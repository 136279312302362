<template>
  <div class="ingredients">
    <div class="header">
      <h2>Découvrez tous nos plats incluant l'ingrédient </h2>
      <h1>{{ name }}</h1>
    </div>
    <div class="card-container">
      <div v-for="c in meals" :key="c.id">
        <MealCard :meal="c"/>
      </div>
    </div>
  </div>
</template>

<script>
import MealCard from '@/components/MealCard.vue'

export default {
  name: 'Ingredients',
  components: {
    MealCard
  },
  props: {
    slug: {
      type: String
    },
    name: {
      type: String
    }
  },
  data () {
    return {
      meals: null
    }
  },
  mounted () {
    fetch('https://www.themealdb.com/api/json/v1/1/filter.php?i=' + this.slug)
      .then(response => response.json())
      .then(response => {
        if (response.meals != null) this.meals = response.meals
        else throw new Error('Bad Ingr')
      })
      .catch(e => {
        this.$router.push({ path: '/404' })
      })
  }
}
</script>

<style scoped>
.card {
  max-width: 200px;
  align-items: start;
}
img.thumb {
  width:100%;
}
h3 {
  min-height: 50px;
}
</style>
